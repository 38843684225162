import ClickSeassonIdItem from '@/model/modules/ecommerce/analytics/ClickSeassonIdItem';

export const typeValues = {
  IMPRESSIONS: 'imp',
  CLICKS: 'clk',
  LEADS: 'conv',
  POST_EVENTS: 'pev',
  CONVERSIONS: 'conv_pev',
};
const TYPE_LEADS_POST_EVENTS = [typeValues.LEADS, typeValues.POST_EVENTS];
const TYPE_ALL = [typeValues.IMPRESSIONS, typeValues.CLICKS, typeValues.LEADS, typeValues.POST_EVENTS];

export default {
  optionTypes: [
    { name: 'IMPRESSIONS', value: typeValues.IMPRESSIONS, allowRoles: ['ADMIN'] },
    { name: 'CLICKS', value: typeValues.CLICKS, allowRoles: ['ADMIN'] },
    { name: 'LEADS', value: typeValues.LEADS, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'POST EVENTS', value: typeValues.POST_EVENTS, allowRoles: ['ADMIN', 'AD_OPS'] },
  ],
  allReportOptions: [
    { name: 'Hour in Druid', value: '__time', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'Pixel', value: 'event_session_id', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'Usuario', value: 'unique_user_id', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'Ocurred On', value: 'time', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'Date Click', value: 'date_click', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'Date Lead', value: 'date_lead', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'Sent to Kafka', value: 'click_time', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'CTIT', value: 'ctit_seconds', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'Client ID', value: 'cl', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'Host', value: 'host', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'Origin', value: 'origin', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    {
      name: 'Advertiser Name',
      value: 'advertiserName',
      checked: true,
      allowTypes: TYPE_ALL,
      allowRoles: ['ADMIN', 'AD_OPS'],
    },
    { name: 'Advertiser ID', value: 'ad', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    {
      name: 'Campaign Name',
      value: 'campaignName',
      checked: true,
      allowTypes: TYPE_ALL,
      allowRoles: ['ADMIN', 'AD_OPS'],
    },
    { name: 'Campaign ID', value: 'camp', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'Offer Name', value: 'offerName', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'Offer ID', value: 'of', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'Offer Country', value: 'offerCountryIso', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    {
      name: 'Creativity Name',
      value: 'creativeName',
      checked: true,
      allowTypes: TYPE_ALL,
      allowRoles: ['ADMIN', 'AD_OPS'],
    },
    { name: 'Creativity ID', value: 'cr', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'Creativity Type', value: 'cr_type', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    {
      name: 'Publisher Name',
      value: 'publisherName',
      checked: true,
      allowTypes: TYPE_ALL,
      allowRoles: ['ADMIN', 'AD_OPS'],
    },
    { name: 'Publisher ID', value: 'pb', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'Sub-Publisher', value: 'sid', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'AFC', value: 'afc', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'Bundle', value: 'bundle', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'Language', value: 'lang', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'IP', value: 'ip', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'IP Country', value: 'countryName', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'IP Country ISO', value: 'countryCode', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'IP Region', value: 'regionName', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'IP City', value: 'cityName', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    {
      name: 'IP Coordinates',
      value: 'coordinate',
      checked: true,
      allowTypes: TYPE_ALL,
      allowRoles: ['ADMIN', 'AD_OPS'],
    },
    { name: 'IP Latitude', value: 'coord_latitude', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'IP Longitude', value: 'coord_longitude', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'IP Carrier Name', value: 'carrier', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'IP Usage Type', value: 'usage_type', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'IP Domain Name', value: 'domainName', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'IP ISP', value: 'isp', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    // { name: 'Proxy IP', value: 'proxy_ip', checked: true, allowTypes: TYPE_ALL },
    // { name: 'Proxy IP Country', value: 'proxy_country', checked: true, allowTypes: TYPE_ALL },
    // { name: 'Proxy IP Country ISO', value: 'proxy_country_code', checked: true, allowTypes: TYPE_ALL },
    // { name: 'Proxy IP Region', value: 'proxy_region_name', checked: true, allowTypes: TYPE_ALL },
    // { name: 'Proxy IP City', value: 'proxy_city_name', checked: true, allowTypes: TYPE_ALL },
    // { name: 'Proxy IP Coordinates', value: 'proxy_coordinate', checked: true, allowTypes: TYPE_ALL },
    // { name: 'Proxy IP Latitude', value: 'proxy_latitude', checked: true, allowTypes: TYPE_ALL },
    // { name: 'Proxy IP Longitude', value: 'proxy_longitude', checked: true, allowTypes: TYPE_ALL },
    // { name: 'Proxy Carrier Name', value: 'proxy_carrier', checked: true, allowTypes: TYPE_ALL },
    // { name: 'Proxy Usage Type', value: 'proxy_usage_type', checked: true, allowTypes: TYPE_ALL },
    // { name: 'Proxy IP domain Name', value: 'proxy_domain_name', checked: true, allowTypes: TYPE_ALL },
    // { name: 'Proxy ISP', value: 'proxy_isp', checked: true, allowTypes: TYPE_ALL },
    { name: 'User Agent', value: 'user_agent', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'Node CDN', value: 'node', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    {
      name: 'Device Vendor',
      value: 'device_brand',
      checked: true,
      allowTypes: TYPE_ALL,
      allowRoles: ['ADMIN', 'AD_OPS'],
    },
    { name: 'Device Model', value: 'device', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'Browser Global', value: 'browser', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'Browser Version', value: 'browser_model', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'Browser', value: 'browser_full', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'OS GLOBAL', value: 'os', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'OS MODEL', value: 'os_model', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'OS', value: 'os_full', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'Referer', value: 'rf', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'Tracking', value: 'tl', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'Tracking Link', value: 'url', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'TrackingType', value: 'ev', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'Target URL', value: 'ru', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'Transaction ID', value: 'tr', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'Order Value', value: 'money', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    {
      name: 'Event Name',
      value: 'postEventName',
      checked: true,
      allowTypes: TYPE_ALL,
      allowRoles: ['ADMIN', 'AD_OPS'],
    },
    { name: 'Event ID', value: 'evid', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    {
      name: 'Suspicious',
      value: 'validation_id',
      checked: true,
      allowTypes: TYPE_ALL,
      allowRoles: ['ADMIN', 'AD_OPS'],
    },
    {
      name: 'Reason',
      value: 'validation_description',
      checked: true,
      allowTypes: TYPE_ALL,
      allowRoles: ['ADMIN', 'AD_OPS'],
    },
    { name: 'Reason Type', value: 'validation_type', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    {
      name: 'Postback Time',
      value: 'occurred_on_postback_url',
      checked: true,
      allowTypes: TYPE_LEADS_POST_EVENTS,
      allowRoles: ['ADMIN'],
    },
    {
      name: 'Postback to Publisher',
      value: 'postback_url',
      checked: true,
      allowTypes: TYPE_LEADS_POST_EVENTS,
      allowRoles: ['ADMIN'],
    },
    {
      name: 'Postback to Publisher Final',
      value: 'postback_url_final',
      checked: true,
      allowTypes: TYPE_LEADS_POST_EVENTS,
      allowRoles: ['ADMIN', 'AD_OPS'],
    },
    {
      name: 'Postback Status Response',
      value: 'status_code_postback_url',
      checked: true,
      allowTypes: TYPE_LEADS_POST_EVENTS,
      allowRoles: ['ADMIN', 'AD_OPS'],
    },
    {
      name: 'Postback Answer Response',
      value: 'response_postback_url',
      checked: false,
      allowTypes: TYPE_LEADS_POST_EVENTS,
      allowRoles: ['ADMIN', 'AD_OPS'],
    },
    {
      name: 'Postback Total Duration',
      value: 'timing_total_postback_url',
      checked: true,
      allowTypes: TYPE_LEADS_POST_EVENTS,
      allowRoles: ['ADMIN', 'AD_OPS'],
    },
    { name: 'Revenue', value: 'event_revenue', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'Currency', value: 'event_currency', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'Expense', value: 'pub_revenue', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'Profit', value: 'revenue', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN', 'AD_OPS'] },
    { name: 'Margin', value: 'commission', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'Margin Publisher', value: 'pub_commission', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'Version', value: 'version', checked: false, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    {
      name: 'Error  Description',
      value: 'error_description',
      checked: true,
      allowTypes: TYPE_ALL,
      allowRoles: ['ADMIN'],
    },
    { name: 'Error ID', value: 'error_id', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'Error Type', value: 'error_type', checked: true, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
    { name: 'Track', value: 'track', checked: false, allowTypes: TYPE_ALL, allowRoles: ['ADMIN'] },
  ],
};

export const CLICK_SESSION_ID_HEADERS = [
  new ClickSeassonIdItem('Suspicious', '', [], ''),
  new ClickSeassonIdItem('Reason', '', [], ''),
  new ClickSeassonIdItem('Reason Type', '', [], ''),
  new ClickSeassonIdItem('Origin', 'origin', [], ''),
  new ClickSeassonIdItem('Client ID', 'cl', [], ''),
  new ClickSeassonIdItem('User', 'unique_user_id', [], ''),
  new ClickSeassonIdItem('Date Click', 'date_click', [], ''),
  new ClickSeassonIdItem('Date Lead', 'date_lead', [typeValues.LEADS, typeValues.POST_EVENTS], ''),
  new ClickSeassonIdItem('CTIT', 'ctit_seconds', [typeValues.LEADS, typeValues.POST_EVENTS], ''),
  new ClickSeassonIdItem('Advertiser', 'advertiserName', [], ''),
  new ClickSeassonIdItem('Campaign', 'campaignName', [], ''),
  new ClickSeassonIdItem('Offer', 'offerName', [], ''),
  new ClickSeassonIdItem('Creativity Type', 'cr_type', [], ''),
  new ClickSeassonIdItem('Creativity Name', 'creativeName', [], ''),
  new ClickSeassonIdItem('Creativity ID', 'cr', [], ''),
  new ClickSeassonIdItem('Publisher', 'publisherName', [], ''),
  new ClickSeassonIdItem('Sub-Publisher', 'sid', [], ''),
  new ClickSeassonIdItem('AFC', 'afc', [], ''),
  new ClickSeassonIdItem('IP', 'ip', [], ''),
  new ClickSeassonIdItem('IP Usage Type', 'usage_type', [], ''),
  new ClickSeassonIdItem('IP ISP', 'isp', [], ''),
  new ClickSeassonIdItem('IP Domain Name', 'domainName', [], ''),
  new ClickSeassonIdItem('IP Carrier Name', 'carrier', [], 'col-span-2'),
  new ClickSeassonIdItem('IP Region', 'regionName', [], ''),
  new ClickSeassonIdItem('IP City', 'cityName', [], ''),
  new ClickSeassonIdItem('IP Coordinates', 'coordinate', [], ''),
  new ClickSeassonIdItem('PROXY IP', 'proxy_ip', [], ''),
  new ClickSeassonIdItem('PROXY Usage Type', 'proxy_usage_type', [], ''),
  new ClickSeassonIdItem('PROXY IP ISP', 'proxy_isp', [], ''),
  new ClickSeassonIdItem('PROXY IP Domain Name', 'proxy_domain_name', [], ''),
  new ClickSeassonIdItem('PROXY Carrier Name', 'proxy_carrier', [], ' col-span-2'),
  new ClickSeassonIdItem('PROXY IP Region', 'proxy_region_name', [], ''),
  new ClickSeassonIdItem('PROXY IP City', 'proxy_city_name', [], ''),
  new ClickSeassonIdItem('PROXY IP Coordinates', 'proxy_coordinate', [], ''),
  new ClickSeassonIdItem('Language', 'lang', [], ''),
  new ClickSeassonIdItem('Bundle', 'bundle', [], ' col-span-2'),
  new ClickSeassonIdItem('User Agent', 'user_agent', [], ' col-span-3'),
  new ClickSeassonIdItem('OS', 'os_full', [], ''),
  new ClickSeassonIdItem('Browser', 'browser_full', [], ' col-span-2'),
  new ClickSeassonIdItem('Device Vendor', 'device_brand', [], ''),
  new ClickSeassonIdItem('Device Model', 'device', [], ' col-span-2'),
  new ClickSeassonIdItem('Referer', 'rf', [], ' col-span-3'),
  new ClickSeassonIdItem('Tracking Link', 'url', [], ' col-span-3'),
  new ClickSeassonIdItem('Target URL', 'ru', [], ' col-span-3'),
  new ClickSeassonIdItem('Transaction', 'tr', [typeValues.LEADS, typeValues.POST_EVENTS], ''),
  new ClickSeassonIdItem('Event Name', 'postEventName', [typeValues.POST_EVENTS], ''),
  new ClickSeassonIdItem('Event ID', 'evid', [typeValues.POST_EVENTS], ''),
  new ClickSeassonIdItem('Postback Time', 'occurred_on_postback_url', [typeValues.LEADS, typeValues.POST_EVENTS], ''),
  new ClickSeassonIdItem(
    'Postback to Publisher Final',
    'postback_url_final',
    [typeValues.LEADS, typeValues.POST_EVENTS],
    ' col-span-2'
  ),
  new ClickSeassonIdItem(
    'Postback Status Response',
    'status_code_postback_url',
    [typeValues.LEADS, typeValues.POST_EVENTS],
    ''
  ),
  new ClickSeassonIdItem(
    'Postback Answer Response',
    'response_postback_url',
    [typeValues.LEADS, typeValues.POST_EVENTS],
    ''
  ),
  new ClickSeassonIdItem(
    'Postback Total Duration',
    'timing_total_postback_url',
    [typeValues.LEADS, typeValues.POST_EVENTS],
    ''
  ),
  new ClickSeassonIdItem('Revenue', 'event_revenue', [], ''),
  new ClickSeassonIdItem('Expense', 'pub_revenue', [], ''),
  new ClickSeassonIdItem('Profit', 'revenue', [], ''),
];

export const donutChartOptions = {
  colors: [
    '#ED8936',
    '#2D66F9',
    '#008FFB',
    '#0c9e1c',
    '#FEB019',
    '#FF4560',
    '#775DD0',
    '#F86624',
    '#F9C80E',
    '#43BCCD',
    '#662E9B',
    '#ea3546',
    '#53868B',
    '#7FFFD4',
    '#FFD700',
  ],
  chart: {
    height: 100,
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
      donut: {
        labels: {
          show: true,
          total: {
            show: true,
            showAlways: true,
            fontSize: '12px',
          },
        },
      },
    },
  },
  dataLabels: {
    enabled: false,
    style: {
      colors: ['#000'],
    },
    formatter: val => {
      return `${val.toFixed(2)}%`;
    },
  },
  legend: {
    show: false,
    position: 'bottom',
    horizontalAlign: 'left',
    fontSize: '12px',
    markers: {
      width: 8,
      height: 8,
      radius: 8,
    },
  },
};
