// @ts-check

export default class ClickSeassonIdItem {
  /**
   * @param {string} value
   * @param {string} title
   * @param {Array} availableTypes
   * @param {string} classes
   */
  constructor(title, value, availableTypes, classes) {
    this.title = title;
    this.value = value;
    this.availableTypes = availableTypes;
    this.classes = classes;
  }
}
